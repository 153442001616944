import { render, staticRenderFns } from "./AuthLayout.vue?vue&type=template&id=10d04339&scoped=true&"
import script from "./AuthLayout.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AuthLayout.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AuthLayout.vue?vue&type=style&index=0&id=10d04339&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d04339",
  null
  
)

export default component.exports